/*------------------------IMPORTS------------------------*/
@import "_breakpoints.scss";
@import "_colors.scss";

/*------------------------CONSTANTS------------------------*/

//--Small
$height-header-small: 27px;
$fontsize-header-small: 16pt;

$fontsize-animalName-small: 10pt;

$height-questionBlock-small: 30px;
$fontsize-theQuestion-small: 16pt;

$height-results-panel-small: 100px;
$height-animalResultsImage-small: 20px;
$width-animalResultsImage-small: 25px;
$height-animalResultsBar-small: 12px;
$fontsize-animalResultsText-small: 7pt;
$height-resultRow-margin-small: 4.5px;
$height-rulesButton-small: 20px;

$fontsize-rankingsHeaderTitle-small: 16pt;
$fontsize-rank-small: 14pt;


//--Normal

$height-header-normal: 40px;
$fontsize-header-normal: 24pt;

$fontsize-animalName-normal: 14pt;

$height-questionBlock-normal: 45px;
$fontsize-theQuestion-normal: 24pt;

$height-results-panel-normal: 120px;
$height-animalResultsImage-normal: 30px;
$width-animalResultsImage-normal: 37.5px;
$height-animalResultsBar-normal: 18px;
$fontsize-animalResultsText-normal: 13pt;
$height-resultRow-margin-normal: 6.5px;
$height-rulesButton-normal: 30px;

$fontsize-rankingsHeaderTitle-normal: 24pt;
$fontsize-rank-normal: 21pt;


//--Large

$height-header-large: 60px;
$fontsize-header-large: 37pt;

$fontsize-animalName-large: 21pt;

$height-questionBlock-large: 68px;
$fontsize-theQuestion-large: 36pt;

$height-results-panel-large: 150px;
$height-animalResultsImage-large: 45px;
$width-animalResultsImage-large: 56.25px;
$height-animalResultsBar-large: 22px;
$fontsize-animalResultsText-large: 15pt;
$height-resultRow-margin-large: 10px;
$height-rulesButton-large: 40px;

$fontsize-rankingsHeaderTitle-large: 36pt;
$fontsize-rank-large: 32pt;

//--Mega
$height-header-mega: 90px;
$fontsize-header-mega: 55pt;

//--Misc variables

//$height-header: calc(40px + 5vmin);
$height-rankings-header: 120px;
$height-button: 35px;
$height-rankings-button-safari: 79px;
$height-spacer: 0px;
$height-animalListing-image: 75px;

$width-button: 170px;
$width-padding: 5vw;
$width-loading-indicator: 400px;
$width-winner-border: 0px;

$padding-tab: 10px;
$padding-modal: 35px;

$fontsize-rulesModalTitle: 48px;
$fontsize-rulesModalBody: 18px;

$fontsize-button: 13px;

$animate-button-amount: 4px;
$animate-jump-height: 40px;

$box-shadow-modal: 0 6px 6px rgba(0,0,0,.2);
$box-shadow-header: 0 7px 4px rgba(0,0,0,0.2);

$z-index-highest-possible: 987654321;
$z-index-highest-except-modals: 5200;


//--animation timing stuff --

$time-duration-cardAppear: .75s;
$time-duration-jumping: 0.65s;
$time-duration-submitBallot: 0.55s;
$time-duration-moveContentFocus: .55s;
$time-duration-theQuestionAppear: .15s;
$time-duration-backgroundAppear: .2s;
$time-duration-punch: .2s;
$time-duration-vs-grows: .2s;
$time-duration-resultsPanelMoveDown: .55s;
$time-duration-resultsPanelShow: .15s;

$time-start-to-submit: .3s;
$time-start-to-resultsMove: .3s;
$time-submit-to-jump: .25s;
$time-submit-to-question: .85s;
$time-question-to-leftCard: .25s;
$time-leftCard-to-vs: .8s;
$time-vs-to-rightCard: .25s;

$time-submitBallot: $time-start-to-submit;
$time-resultsPanelMoveDown: $time-start-to-resultsMove;

$time-startJumping: $time-submitBallot + $time-submit-to-jump;
$time-theQuestionAppears: $time-submitBallot + $time-submit-to-question;

$time-leftCardAppear: $time-theQuestionAppears + $time-question-to-leftCard;

$time-vs-appear: $time-leftCardAppear + $time-leftCard-to-vs;

$time-rightCardAppear: $time-vs-appear + $time-vs-to-rightCard;


$time-initial-start-to-question: 0s;

$time-initial-question-appear: $time-initial-start-to-question + $time-question-to-leftCard;
$time-initial-leftCard-appear: $time-initial-question-appear + $time-question-to-leftCard;
$time-initial-vs-appear: $time-initial-leftCard-appear + $time-leftCard-to-vs;
$time-initial-rightCard-appear: $time-initial-vs-appear + $time-vs-to-rightCard;

/* ------------------------TOP-LEVEL------------------------ */

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.wf-loading * {
  font-size: 0pt !important; /* Hide text until webfonts load */
  background-color: $color-background;
}

.app {
  height: 100vh;
}

button {
  border: none;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: $fontsize-button;
  width: $width-button;
  outline: none;
  cursor: pointer;
  font-family: 'Roboto Slab', 'Komika Axis', "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  letter-spacing: 2px;
}

button.viewRankings {
  background-color: $color-background-darker;
  color: $color-text-medium-light;
  position: relative;
  top: calc(-#{$height-button});
  height: $height-button;
  padding-top: $padding-tab;
  padding-bottom: $padding-tab;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  left: calc(50vw - (#{$width-button} / 2));
}

button.viewRankings:hover {
  height: calc(#{$height-button} + #{$animate-button-amount});
  top: calc(-#{$height-button} - #{$animate-button-amount})
}

button.viewBallots {
  background-color: $color-background-dark;
  color: $color-text-dark;
  position: absolute;
  height: $height-button;
  padding-top: $padding-tab;
  padding-bottom: $padding-tab;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  left: calc(95vw - (#{$width-button}));
  z-index: 5;
}

button.viewBallots:hover {
  height: calc(#{$height-button} + #{$animate-button-amount});
  padding-top: calc(#{$padding-tab} + #{$animate-button-amount});
}

/* While jumping is occurring, hide button */
button.viewBallots.jump {
  background-color: $color-background-darker;
  color: $color-background-darker;
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  @keyframes showRankings {
    from {
    }

    to {
      transform: translateY(calc(-100vh + #{$height-header-small} - #{$height-spacer}));
    }
  }
  @keyframes showBallots {
    from {
      transform: translateY(calc(-100vh + #{$height-header-small} - #{$height-spacer}));
    }

    to {}
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  @keyframes showRankings {
    from {
    }

    to {
      transform: translateY(calc(-100vh + #{$height-header-normal} - #{$height-spacer}));
    }
  }
  @keyframes showBallots {
    from {
      transform: translateY(calc(-100vh + #{$height-header-normal} - #{$height-spacer}));
    }

    to {}
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  @keyframes showRankings {
    from {
    }

    to {
      transform: translateY(calc(-100vh + #{$height-header-large} - #{$height-spacer}));
    }
  }
  @keyframes showBallots {
    from {
      transform: translateY(calc(-100vh + #{$height-header-large} - #{$height-spacer}));
    }

    to {}
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 1150px) and  (min-height: 726px){
  @keyframes showRankings {
    from {
    }

    to {
      transform: translateY(calc(-100vh + #{$height-header-mega} - #{$height-spacer}));
    }
  }
  @keyframes showBallots {
    from {
      transform: translateY(calc(-100vh + #{$height-header-mega} - #{$height-spacer}));
    }

    to {}
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  @keyframes showRankings {
    from {
    }

    to {
      transform: translateY(calc(-100vh + #{$height-header-small} - #{$height-spacer}));
    }
  }
  @keyframes showBallots {
    from {
      transform: translateY(calc(-100vh + #{$height-header-small} - #{$height-spacer}));
    }

    to {}
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px){
  //Horizontal, medium
  @keyframes showRankings {
    from {
    }

    to {
      transform: translateY(calc(-100vh + #{$height-header-normal} - #{$height-spacer}));
    }
  }
  @keyframes showBallots {
    from {
      transform: translateY(calc(-100vh + #{$height-header-normal} - #{$height-spacer}));
    }

    to {}
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  @keyframes showRankings {
    from {
    }

    to {
      transform: translateY(calc(-100vh + #{$height-header-large} - #{$height-spacer}));
    }
  }
  @keyframes showBallots {
    from {
      transform: translateY(calc(-100vh + #{$height-header-large} - #{$height-spacer}));
    }

    to {}
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 1150px) and  (min-height: 833px){
  //Horizontal, large
  @keyframes showRankings {
    from {
    }

    to {
      transform: translateY(calc(-100vh + #{$height-header-mega} - #{$height-spacer}));
    }
  }
  @keyframes showBallots {
    from {
      transform: translateY(calc(-100vh + #{$height-header-mega} - #{$height-spacer}));
    }

    to {}
  }
}



@font-face {
  font-family: 'Back Issues BB';
  src: url("BackIssuesBB.woff2") format('woff2'), url("BackIssuesBB.woff") format('woff'); /*TODO: Do we need both of these files? */
  font-weight: normal;
  font-style: normal;
}

/*------------------------MODALS------------------------*/

.rulesModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.rulesModalContainer.hidden {
  display: none;
}

.modalBackground {
  width: 100%;
  height: 100%;
  background-color: $color-background-modal;
  cursor: pointer;
}

.rulesModalFrame {
  cursor: auto;
  margin: 0;
  background-color: $color-background-light;
  color: $color-text-dark;
  box-shadow: $box-shadow-modal;
}

.rulesModalContainer {
      z-index: $z-index-highest-possible;
}

@media (min-width: 600px), (min-height: 700px) {
  .rulesModalFrame {
    position: absolute;
    width: 550px;
    /* This part just centers the modal on the screen */
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);

  }
}

@media (max-width: 600px) , (max-height: 700px) {
  .rulesModalFrame {

    position: absolute;
    width: 90vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  .rulesModalBody {
    overflow: scroll;
    max-height: 40vh;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
  }
}

.rulesModalHeader {
  margin: $padding-modal;
}

.rulesModalTitle {
  font-family: 'Special Elite', "Courier New", Courier, monospace;
  font-size: $fontsize-rulesModalTitle;
}

.rulesModalBody {
  margin: $padding-modal $padding-modal calc(#{$padding-modal} + 20px);
  font-family: 'Special Elite', "Courier New", Courier, monospace;
  font-size: $fontsize-rulesModalBody;
}

.rulesModalBody ol li {
  padding: 10px;
}

.closeButton {
  position: absolute;
  top: 30px;
  right: 35px;
  width: 32px;
  height: 32px;
}

.closeButton:hover g g circle {
  fill: $color-highlight-main;
  cursor: pointer;
}

.closeButton g g path {
  stroke: $color-text-lighter;
}

.rulesModalBody ol {
  list-style: decimal inside none;
}
/* ------------------------HEADER------------------------ */

.header {
  position: relative;
  top: 0;
  left: 0;
  z-index: $z-index-highest-except-modals;
  height: $height-header-normal;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  background-color: $color-highlight-main;
  box-shadow: $box-shadow-header;
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .header {
    height: $height-header-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .header {
    height: $height-header-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .header {
    height: $height-header-large;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 1150px) and  (min-height: 726px){
  //Vertical, mega
  .header {
    height: $height-header-mega;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .header {
    height: $height-header-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px){
  //Horizontal, medium
  .header {
    height: $height-header-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .header {
    height: $height-header-large;
  }
}

@media (min-aspect-ratio: 3/4) and (min-width: 1150px) and  (min-height: 833px){
  //Horizontal, mega
  .header {
    height: $height-header-mega;
  }
}


.header-text {
  color: $color-text-medium;
  font-size: $fontsize-header-normal;
  margin-left: $width-padding;
  text-shadow: 0 0.8vmin rgba(0,0,0,0.2);
  font-family: 'Alfa Slab One', 'Komika Axis', "Arial Black", sans-serif;
  /* Or Komika Axis */
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .header-text {
    font-size: $fontsize-header-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .header-text {
    font-size: $fontsize-header-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .header-text {
    font-size: $fontsize-header-large;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 1150px) and  (min-height: 726px){
  //Vertical, large
  .header-text {
    font-size: $fontsize-header-mega;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .header-text {
    font-size: $fontsize-header-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .header-text {
    font-size: $fontsize-header-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .header-text {
    font-size: $fontsize-header-large;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 1150px) and  (min-height: 833px){
  //Horizontal, mega
  .header-text {
    font-size: $fontsize-header-mega;
  }
}




/* ------------------------BALLOTVIEWER------------------------ */

.theCard {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.cardHover {
  grid-row: 1;
  grid-column: 1;
  z-index: 1;
}

.theQuestionBlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  order: 3;
  margin-left: 10px; /* A fudge from direct center to account for rules button visual weight */
  flex-grow: 0;
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .theQuestionBlock {
    height: $height-questionBlock-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .theQuestionBlock {
    height: $height-questionBlock-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .theQuestionBlock {
    height: $height-questionBlock-large;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .theQuestionBlock {
    height: $height-questionBlock-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .theQuestionBlock {
    height: $height-questionBlock-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .theQuestionBlock {
    height: $height-questionBlock-large;
  }
}

.theQuestionPlusRulesButton {
  display: flex;
  justify-content: center;
}

.theQuestion {
  font-family:  "BackIssuesBB", "Arial Black", Gadget, sans-serif;
  color: $color-text-dark;
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .theQuestion {
    font-size: $fontsize-theQuestion-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .theQuestion {
    font-size: $fontsize-theQuestion-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .theQuestion {
    font-size: $fontsize-theQuestion-large;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .theQuestion {
    font-size: $fontsize-theQuestion-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .theQuestion {
    font-size: $fontsize-theQuestion-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .theQuestion {
    font-size: $fontsize-theQuestion-large;
  }
}


.loadingIndicator {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $width-loading-indicator;
  height: $width-loading-indicator;
  margin: calc(-#{$width-loading-indicator} / 2) 0 0 calc(-#{$width-loading-indicator} / 2);
  opacity: 0.5;
  animation: growPartial 0.4s ease-out infinite;
  animation-direction: alternate;
}


@keyframes grow {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1.0);
  }
}
@keyframes growPartial {
  from {
    transform: scale(.5);
  }

  to {
    transform: scale(1.0);
  }
}

.ballotViewer {
  //height: calc(100vh - #{$height-header-normal} - #{$height-results-panel-normal});
  width: 90vw;
  padding-left: 5vw;
  padding-right: 5vw;
  order: 2;
  display: grid;
  background-color: $color-background;
  position: relative;
  top: 0;
  left: 0;
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .ballotViewer {
    height: calc(100vh - #{$height-header-small} - #{$height-results-panel-small});
  }
}

@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .ballotViewer {
    height: calc(100vh - #{$height-header-normal} - #{$height-results-panel-normal});
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .ballotViewer {
    height: calc(100vh - #{$height-header-large} - #{$height-results-panel-large});
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 1150px) and  (min-height: 726px){
  //Vertical, large
  .ballotViewer {
    height: calc(100vh - #{$height-header-mega} - #{$height-results-panel-large});
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .ballotViewer {
    height: calc(100vh - #{$height-header-small} - #{$height-results-panel-small});
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .ballotViewer {
    height: calc(100vh - #{$height-header-normal} - #{$height-results-panel-normal});
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .ballotViewer {
    height: calc(100vh - #{$height-header-large} - #{$height-results-panel-large});
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 1150px) and  (min-height: 833px){
  //Horizontal, mega
  .ballotViewer {
    height: calc(100vh - #{$height-header-mega} - #{$height-results-panel-large});
  }
}


.animalBlock {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 0;
  left: 0;
  flex-grow: 5;
}

.ballotBackground {
  position: absolute;
  z-index: -1;
  width: 70vw;
  height: 70vw;
  top: 50%;
  left: 50%;
  margin: -35vw 0 0 -35vw;
}

.ballotBackground.incoming {
  transform: scale(0);
  animation-name: grow;
  animation-duration: $time-duration-vs-grows;
  animation-fill-mode: forwards;
  animation-delay: $time-vs-appear;
}

.theQuestionBlock.incoming {
  transform: scale(0);
  animation-name: grow;
  animation-duration: $time-duration-theQuestionAppear;
  animation-fill-mode: forwards;
  animation-delay: $time-theQuestionAppears;
}

.ballotBackground.initial_incoming {
  transform: scale(0);
  animation-name: grow;
  animation-duration: $time-duration-vs-grows;
  animation-fill-mode: forwards;
  animation-delay: $time-initial-vs-appear;
}

.theQuestionBlock.initial_incoming {
  transform: scale(0);
  animation-name: grow;
  animation-duration: $time-duration-theQuestionAppear;
  animation-fill-mode: forwards;
  animation-delay: $time-initial-question-appear;
}

.ballot {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  /* keeps it below the ballotBox */
  grid-column: 1;
  /*all ballots appear in same column and row */
  grid-row: 1;
}

.cardAndHover {
  width: 100%;
  height: 100%;
  display: grid;
  cursor: pointer;
}

.rulesButton {
  margin-left: 5px;
  /* Unfudge */
  fill: $color-small-button;
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .rulesButton {
    height: $height-rulesButton-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .rulesButton {
    height: $height-rulesButton-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .rulesButton {
    height: $height-rulesButton-large;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .rulesButton {
    height: $height-rulesButton-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .rulesButton {
    height: $height-rulesButton-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .rulesButton {
    height: $height-rulesButton-large;
  }
}

.theQuestionBlock:hover {
  fill: $color-highlight-main;
}

.rulesButton:hover {
  fill: $color-highlight-main;
  cursor: pointer;
}

.ballot.outgoingBallot {
  animation-name: moveDown;
  animation-duration: $time-duration-submitBallot;
  /* TODO: File bug, background moves early when delay is more than 0 */
  animation-fill-mode: forwards;
  animation-delay: $time-submitBallot;

  animation-timing-function: ease-out;
}


@keyframes moveDown {
  from {}

  to {
    transform: translateY(calc(150vh));
  }
}

.incomingleft {
  order: 1;
  transform: translateX(-100vw);
  animation-name: comeInFromLeft;
  animation-duration: $time-duration-cardAppear;
  animation-fill-mode: forwards;
  animation-delay: $time-leftCardAppear;
}

.initial_incomingleft {
  order: 1;
  transform: translateX(-100vw);
  animation-name: comeInFromLeft;
  animation-duration: $time-duration-cardAppear;
  animation-fill-mode: forwards;
  animation-delay: $time-initial-leftCard-appear;
}

.incomingright {
  order: 2;
  transform: translateX(100vw);
  animation-name: comeInFromRight;
  animation-duration: $time-duration-cardAppear;
  animation-fill-mode: forwards;
  animation-delay: $time-rightCardAppear;
}

.initial_incomingright {
  order: 2;
  transform: translateX(100vw);
  animation-name: comeInFromRight;
  animation-duration: $time-duration-cardAppear;
  animation-fill-mode: forwards;
  animation-delay: $time-initial-rightCard-appear;
}

@keyframes comeInFromLeft {
  from {
    transform: translateX(-100vw) ;
  }

  to {
    transform: translateX(0vw);
  }
}
@keyframes comeInFromRight {
  from {
    transform: translateX(100vw);
  }

  to {
    transform: translateX(0vw);
  }
}

.animal {
  background-color: $color-background-light;
}

.hiddenBallot {
  display: none;
}

.animalNameBox {
  order: 2;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.animalName {
  font-family: 'Special Elite', 'Helvetica', 'Arial', sans-serif;
//  font-size: $fontsize-animalName-normal;
}


@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .animalName {
    font-size: $fontsize-animalName-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .animalName {
    font-size: $fontsize-animalName-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .animalName {
    font-size: $fontsize-animalName-large;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .animalName {
    font-size: $fontsize-animalName-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .animalName {
    font-size: $fontsize-animalName-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .animalName {
    font-size: $fontsize-animalName-large;
  }
}

.hidden {
  width: 0;
  height: 0;
  display: none;
}

.photo {
  order: 1;
  height: 80%;
  width: 100%;
}

.cardHover {
  background-image: url("./check.png");
  background-size: 25% 25%;
  background-position: top 5% left 5%;
  background-repeat: no-repeat;
  opacity: 0;

}

.cardAndHover {
    box-shadow: 0 6px 6px rgba(0,0,0,.2); /* 0px 0px 160px rgba(0,0,0,.2); */
}


.cardHover:hover {
  opacity: 0.5;


}

.cardHover:active:hover {
  animation-name: getReady;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.cardWrapper:active:hover {
    animation-name: punchDown;
    animation-duration: 1.5s;
    animation-duration: ease-out;
    animation-fill-mode: forwards;
}

.cardWrapper.votedFor {
  //transform: perspective(200px) translateZ(-30px);
  animation-name: pullUp;
  animation-duration: .05s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

}

.cardWrapper.votedFor .cardAndHover {
      box-shadow: 0px 0px 12px rgba(0,0,0,.3);
}

.cardHover.votedFor {
    opacity: 1.0;
}

.animal.votedFor {
  animation-name: punch;
  animation-duration: $time-duration-punch;
}


.outerCardWrapper {
  flex-grow: 5;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.outerCardWrapper.LEFT {
  order: 1;
}

.outerCardWrapper.RIGHT {
  order: 2;
}

  /* Make the animal on the right face inward. All animals should face right by default */
.cardWrapper.RIGHT .animal .cardAndHover .theCard .photo {
  transform: scaleX(-1);
}

.cardWrapper {
  display: flex;
  flex-direction: row;
}


/* ------------------------ASPECT-RATIO-SPECIFIC------------------------ */

@media (max-aspect-ratio: 5/14) {
  .animalBlock {
    flex-direction: column;
  }

  .animal {
    width: 90vw;
    height: 90vw;
  }

  .cardWrapper {
    justify-content: center;
  }
}
@media (min-aspect-ratio: 5/14) and (max-aspect-ratio: 3/4) {
  .animalBlock {
    flex-direction: column;
  }

  .animal {
    flex-direction: column;
    width: 30vh;
    height: 30vh;
  }

  .cardWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .card {
    background: gold;
    width: 43vw;
    height: 43vw;
    padding: 0;
    order: 1;
  }

  .vs {
    flex-grow: 0;
  }
}
@media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 7/5) {
  .animalBlock.incomingAnimalBlock {
    flex-direction: row;
  }

  .cardWrapper.LEFT {
    justify-content: flex-start;
  }

  .cardWrapper.RIGHT {
    justify-content: flex-end;
  }

  .animal {
    width: 35vw;
    height: 35vw;
  }
}
@media (min-aspect-ratio: 7/5) {

  .cardWrapper.LEFT {
    justify-content: flex-start;
  }

  .cardWrapper.RIGHT {
    justify-content: flex-end;
  }
}

@media (min-aspect-ratio: 7/5) {
  //Horizontal, small
  .animal {
    width: calc(90vh - #{$height-header-small} - #{$height-results-panel-small} - #{$height-questionBlock-small});
    height: calc(90vh - #{$height-header-small} - #{$height-results-panel-small} - #{$height-questionBlock-small});
  }
}
@media (min-aspect-ratio: 7/5) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .animal {
    width: calc(90vh - #{$height-header-normal} - #{$height-results-panel-normal} - #{$height-questionBlock-normal});
    height: calc(90vh - #{$height-header-normal} - #{$height-results-panel-normal} - #{$height-questionBlock-normal});
  }
}
@media (min-aspect-ratio: 7/5) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .animal {
    width: calc(90vh - #{$height-header-large} - #{$height-results-panel-large} - #{$height-questionBlock-large});
    height: calc(90vh - #{$height-header-large} - #{$height-results-panel-large} - #{$height-questionBlock-large});
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 1150px) and  (min-height: 833px){
  //Horizontal, mega
  .animal {
    width: calc(90vh - #{$height-header-mega} - #{$height-results-panel-large}  - #{$height-questionBlock-large});
    height: calc(90vh - #{$height-header-mega} - #{$height-results-panel-large} - #{$height-questionBlock-large});
  }
}

/* ------------------------BALLOT BOX------------------------ */
.rankingsHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rankingsHeaderTitle {
  font-family: 'Special Elite', 'Helvetica', 'Arial', sans-serif;
  //font-size: $fontsize-rankingsHeaderTitle;
  color: $color-text-light;
  text-shadow: 0 2px 2px rgba(0,0,0,0.4);
}


@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .rankingsHeaderTitle {
    font-size: $fontsize-rankingsHeaderTitle-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .rankingsHeaderTitle {
    font-size: $fontsize-rankingsHeaderTitle-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .rankingsHeaderTitle {
    font-size: $fontsize-rankingsHeaderTitle-large;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .rankingsHeaderTitle {
    font-size: $fontsize-rankingsHeaderTitle-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .rankingsHeaderTitle {
    font-size: $fontsize-rankingsHeaderTitle-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .rankingsHeaderTitle {
    font-size: $fontsize-rankingsHeaderTitle-large;
  }
}

.descriptionBlock {
  height: 100%;
  margin-left: 10px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.imageBlock {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}




.rank {
  margin-right: 12px;
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .rank {
    font-size: $fontsize-rank-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .rank {
    font-size: $fontsize-rank-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .rank {
    font-size: $fontsize-rank-large;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .rank {
    font-size: $fontsize-rank-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .rank {
    font-size: $fontsize-rank-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .rank {
    font-size: $fontsize-rank-large;
  }
}

.description .name {
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .description .name {
    font-size: $fontsize-animalName-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .description .name {
    font-size: $fontsize-animalName-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .description .name {
    font-size: $fontsize-animalName-large;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .description .name {
    font-size: $fontsize-animalName-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .description .name {
    font-size: $fontsize-animalName-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .description .name {
    font-size: $fontsize-animalName-large;
  }
}


.animalListing img {
  height: $height-animalListing-image;
  padding-left: 15px;
}

.animalListing {
  font-family: 'Special Elite', 'Helvetica', 'Arial', sans-serif;;
  font-size: 16pt;
  color: $color-text-light;
  text-shadow: 0 1px 1px rgba(0,0,0,0.6);
  display: flex;
}

.ballotBox {
  //height: calc(100vh + #{$height-results-panel-normal});
  background-color: $color-background;
  position: relative;
  top: 0;
  left: 0;
}



@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .ballotBox {
    height: calc(100vh + #{$height-results-panel-small});
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .ballotBox {
      height: calc(100vh + #{$height-results-panel-normal});
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .ballotBox {
      height: calc(100vh + #{$height-results-panel-large});
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .ballotBox {
      height: calc(100vh + #{$height-results-panel-small});
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .ballotBox {
      height: calc(100vh + #{$height-results-panel-normal});
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .ballotBox {
      height: calc(100vh + #{$height-results-panel-large});
  }
}

.resultsPanelSpaceHolder {
  height: $height-results-panel-normal;
  background-color: $color-background;
}



@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .resultsPanelSpaceHolder {
    height: $height-results-panel-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .resultsPanelSpaceHolder {
    height: $height-results-panel-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .resultsPanelSpaceHolder {
    height: $height-results-panel-large;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .resultsPanelSpaceHolder {
    height: $height-results-panel-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .resultsPanelSpaceHolder {
    height: $height-results-panel-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .resultsPanelSpaceHolder {
    height: $height-results-panel-large;
  }
}

.resultsPanel {
  background-color: $color-background-dark;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  //height: $height-results-panel-normal;
  //top: calc(-1 *  #{$height-results-panel});
  width: 100vw;
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .resultsPanel {
    height: $height-results-panel-small;
    top: calc(-1 * #{$height-results-panel-small})
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .resultsPanel {
    height: $height-results-panel-normal;
    top: calc(-1 * #{$height-results-panel-normal})
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .resultsPanel {
    height: $height-results-panel-large;
    top: calc(-1 * #{$height-results-panel-large})
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .resultsPanel {
    height: $height-results-panel-small;
    top: calc(-1 * #{$height-results-panel-small})
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .resultsPanel {
    height: $height-results-panel-normal;
    top: calc(-1 * #{$height-results-panel-normal})
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .resultsPanel {
    height: $height-results-panel-large;
    top: calc(-1 * #{$height-results-panel-large})
  }
}

.resultsPanel.rewrite {
  animation-name: moveDown;
  animation-duration: $time-duration-resultsPanelMoveDown;
  animation-fill-mode: forwards;
  animation-delay: $time-resultsPanelMoveDown; /* TODO: Total of these two needs to be the same as the delay in actionsAndReducers */
}

.resultsPanel.show {
  animation-name: bounceUp;
  animation-fill-mode: forwards;
  justify-content: ease-out;
  animation-duration: $time-duration-resultsPanelShow;
}

@keyframes slideUp {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0vh);
  }
}


@keyframes bounceUp {
  0% {
    transform: translateY(calc(#{$height-results-panel-normal}));
  }


  100% {
      transform: translateY(0);
  }
}

@media (max-width: 720px), (max-height: 650px) {
  @keyframes bounceUp {
    0% {
      transform: translateY(calc(#{$height-results-panel-small}));
    }


    100% {
        transform: translateY(0);
    }
  }
}

@keyframes printUp2 {
  0% {
    transform: translateY(calc(#{$height-results-panel-normal}));
  }

  6% {
    transform: translateY(calc(#{$height-results-panel-normal} * .90));
  }

  12% {
    transform: translateY(calc(#{$height-results-panel-normal} * .90));
  }

  18% {
      transform: translateY(calc(#{$height-results-panel-normal} * .80));
  }

  24% {
      transform: translateY(calc(#{$height-results-panel-normal} * .80));
  }

  30% {
      transform: translateY(calc(#{$height-results-panel-normal} * .70));
  }

  36% {
      transform: translateY(calc(#{$height-results-panel-normal} * .70));
  }

  42% {
      transform: translateY(calc(#{$height-results-panel-normal} * .60));
  }

  48% {
      transform: translateY(calc(#{$height-results-panel-normal} * .60));
  }
  54% {
      transform: translateY(calc(#{$height-results-panel-normal} * .50));
  }

  60% {
      transform: translateY(calc(#{$height-results-panel-normal} * .50));
  }

  66% {
      transform: translateY(calc(#{$height-results-panel-normal} * .40));
  }

  72% {
      transform: translateY(calc(#{$height-results-panel-normal} * .40));
  }

  78% {
      transform: translateY(calc(#{$height-results-panel-normal} * .30));
  }

  84% {
      transform: translateY(calc(#{$height-results-panel-normal} * .30));
  }

  90% {
      transform: translateY(calc(#{$height-results-panel-normal} * .30));
  }

  100% {
      transform: translateY(0);
  }

  // 80% {
  //   transform: translateY(40px);
  // }
  //
  // 85% {
  //   transform: translateY(40px);
  // }

  // 85% {
  //   transform: translateY(80px);
  // }
  //
  // 90% {
  //   transform: translateY(40px);
  // }
  //
  // 95% {
  //   transform: translateY(40px);
  // }

  100% {
    transform: translateY(0px);
  }
}

@keyframes printUp {
  0% {
    transform: translateY(200px);
  }

  10% {
    transform: translateY(160px);
  }

  20% {
    transform: translateY(160px);
  }

  30% {
    transform: translateY(120px);
  }

  40% {
    transform: translateY(120px);
  }

  50% {
    transform: translateY(80px);
  }

  60% {
    transform: translateY(80px);
  }

  70% {
    transform: translateY(40px);
  }

  80% {
    transform: translateY(40px);
  }

  90% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}




.winRateRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: $width-padding;
  margin-right: $width-padding;
  flex-grow: 0;
//  margin-top: $height-resultRow-margin-small;
}


@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .winRateRow {
    margin-top: $height-resultRow-margin-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .winRateRow {
    margin-top: $height-resultRow-margin-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .winRateRow {
    margin-top: $height-resultRow-margin-large;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .winRateRow {
    margin-top: $height-resultRow-margin-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .winRateRow {
    margin-top: $height-resultRow-margin-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .winRateRow {
    margin-top: $height-resultRow-margin-large;
  }
}

//TODO: What is this even doing?
.resultsPanelPhotoContainer {
  // width: 50px;
  // height: 40px;
  display: grid;
  order: 1;
  flex-grow: 0;
}

.resultsPanelPhotoContainer.agree {
    border:  $width-winner-border solid $color-highlight-main;
    margin-left: calc(-#{$width-winner-border});
}

.resultsPanelPhotoContainer img {
  //TODO: Used to have width: 40px here
  //height: $height-animalResultsImage-normal;
  grid-row: 1;
  grid-column: 1;
}


@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .resultsPanelPhotoContainer img {
    height: $height-animalResultsImage-small;
    width: $width-animalResultsImage-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .resultsPanelPhotoContainer img {
    height: $height-animalResultsImage-normal;
    width: $width-animalResultsImage-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .resultsPanelPhotoContainer img {
    height: $height-animalResultsImage-large;
    width: $width-animalResultsImage-large;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .resultsPanelPhotoContainer img {
    height: $height-animalResultsImage-small;
    width: $width-animalResultsImage-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .resultsPanelPhotoContainer img {
    height: $height-animalResultsImage-normal;
    width: $width-animalResultsImage-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .resultsPanelPhotoContainer img {
    height: $height-animalResultsImage-large;
    width: $width-animalResultsImage-large;
  }
}


.checkAnnotation {
  grid-row: 1;
  grid-column: 1;
  z-index: 1;
  background-image: url("./check.png");
  background-size: 25% 25%;
  background-position: top 5% left 5%;
  background-repeat: no-repeat;
}

.checkAnnotation.disagree {
  display: none;
}

.percentBarBlock {
  //height: $height-animalResultsBar-normal;
  flex-grow: 1;
  order: 2;
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  margin-left: 15px;
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .percentBarBlock {
    height: $height-animalResultsBar-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .percentBarBlock {
    height: $height-animalResultsBar-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .percentBarBlock {
    height: $height-animalResultsBar-large;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .percentBarBlock {
    height: $height-animalResultsBar-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .percentBarBlock {
    height: $height-animalResultsBar-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .percentBarBlock {
    height: $height-animalResultsBar-large;
  }
}

.percentBarBlock.agree {
  margin-left: calc(15px - #{$width-winner-border});; /* offsets the border */
}

.percentBar {
  display: inline;
  background-color: $color-highlight-secondary;
  grid-column: 1;
  grid-row: 1;
  //height: 25px;
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .percentBar {
    height: $height-animalResultsBar-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .percentBar {
    height: $height-animalResultsBar-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .percentBar {
    height: $height-animalResultsBar-large;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .percentBar {
    height: $height-animalResultsBar-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .percentBar {
    height: $height-animalResultsBar-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .percentBar {
    height: $height-animalResultsBar-large;
  }
}

.resultsSpacer {
  height: $height-button / 1;
  flex-grow: 0;
  flex-shrink: 0;
}


.animalNameOnPercentBar {
  grid-column: 1;
  grid-row: 1;
  font-family: 'Special Elite';
//  font-size:
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3px;
  padding-top: 3px; /* Adjusts so font actually looks centered */
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .animalNameOnPercentBar {
    font-size: $fontsize-animalResultsText-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .animalNameOnPercentBar {
    font-size: $fontsize-animalResultsText-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .animalNameOnPercentBar {
    font-size: $fontsize-animalResultsText-large;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .animalNameOnPercentBar {
    font-size: $fontsize-animalResultsText-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .animalNameOnPercentBar {
    font-size: $fontsize-animalResultsText-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .animalNameOnPercentBar {
    font-size: $fontsize-animalResultsText-large;
  }
}

.percentBarAndAnimalName {
  display: grid;
}

.percentAsNumbers {
  order: 3;
  flex-grow: 0;
  margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
      font-family: "Special Elite";
    margin-top: 4px; /* Adjusts so font actually looks centered */
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .percentAsNumbers {
    font-size: $fontsize-animalResultsText-small;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .percentAsNumbers {
    font-size: $fontsize-animalResultsText-normal;
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .percentAsNumbers {
    font-size: $fontsize-animalResultsText-large;
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .percentAsNumbers {
    font-size: $fontsize-animalResultsText-small;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .percentAsNumbers {
    font-size: $fontsize-animalResultsText-normal;
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .percentAsNumbers {
    font-size: $fontsize-animalResultsText-large;
  }
}


.ballotBox.jump {
  animation-name: jump;
  animation-duration: $time-duration-jumping;
  animation-timing-function: linear;
  animation-delay: $time-startJumping;
}

@keyframes pullUp {
  100% {
    transform: perspective(200px) translateZ(-30px);
  }


}

@keyframes getReady {
  100% {
    transform: perspective(200px) translateZ(40px);
  }
}



@keyframes jump {
  20%,
  53%,
  80%,
  from,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, calc(-#{$animate-jump-height}), 0);
    transform: translate3d(0, calc(-#{$animate-jump-height}), 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, calc(-#{$animate-jump-height} / 2), 0);
    transform: translate3d(0, calc(-#{$animate-jump-height} / 2), 0);
  }

  90% {
    -webkit-transform: translate3d(0,calc(-#{$animate-jump-height} / 6),0);
    transform: translate3d(0,calc(-#{$animate-jump-height} / 6),0);
  }
}

.animalListing {
  height: 100px;
}

.animalListing:nth-child(odd) {
  background-color: #C82D2D;
}

.rankingsPanel {
  overflow: auto;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  //height: calc(100vh - #{$height-header-normal});
  padding-left: $width-padding;
  padding-right: $width-padding;
  background-color: $color-highlight-main;
  z-index: 1;
  position: relative;
  top: 0;
  left: 0;
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .rankingsPanel {
    height:  calc(100vh - #{$height-header-small});
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .rankingsPanel {
    height:  calc(100vh - #{$height-header-normal});
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .rankingsPanel {
    height:  calc(100vh - #{$height-header-large});
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 1150px) and  (min-height: 726px){
  //Vertical, mega
  .rankingsPanel {
    height:  calc(100vh - #{$height-header-mega});
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .rankingsPanel {
    height:  calc(100vh - #{$height-header-small});
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .rankingsPanel {
    height:  calc(100vh - #{$height-header-normal});
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .rankingsPanel {
    height:  calc(100vh - #{$height-header-large});
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 1150px) and  (min-height: 833px){
  //Horizontal, mega
  .rankingsPanel {
    height:  calc(100vh - #{$height-header-mega});
  }
}

.rankingsHeader {
  height: $height-rankings-header;
  position: relative;
  top: 0;
  left: 0;
}

.rankingsContent {
  //height: calc(100vh - #{$height-header-normal} - #{$height-rankings-header});
}

@media (max-aspect-ratio: 3/4) {
  //Vertical, small
  .rankingsContent {
    height:  calc(100vh - #{$height-header-small} - #{$height-rankings-header});
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 484px) and (min-height: 695px) {
  //Vertical, medium
  .rankingsContent {
    height:  calc(100vh - #{$height-header-normal} - #{$height-rankings-header});
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 726px){
  //Vertical, large
  .rankingsContent {
    height:  calc(100vh - #{$height-header-large} - #{$height-rankings-header});
  }
}
@media (max-aspect-ratio: 3/4) and (min-width: 1150px) and  (min-height: 726px){
  //Vertical, large
  .rankingsContent {
    height:  calc(100vh - #{$height-header-mega} - #{$height-rankings-header});
  }
}
@media (min-aspect-ratio: 3/4) {
  //Horizontal, small
  .rankingsContent {
    height:  calc(100vh - #{$height-header-small} - #{$height-rankings-header});
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 703px) and (min-height: 555px) {
  //Horizontal, medium
  .rankingsContent {
    height:  calc(100vh - #{$height-header-normal} - #{$height-rankings-header});
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 780px) and  (min-height: 833px){
  //Horizontal, large
  .rankingsContent {
    height:  calc(100vh - #{$height-header-large} - #{$height-rankings-header});
  }
}
@media (min-aspect-ratio: 3/4) and (min-width: 1150px) and  (min-height: 833px){
  //Horizontal, large
  .rankingsContent {
    height:  calc(100vh - #{$height-header-mega} - #{$height-rankings-header});
  }
}

.spacer {
  height: $height-spacer;
}


.contents.show_rankings {
  animation-name: showRankings;
  animation-duration: $time-duration-moveContentFocus;
  animation-fill-mode: forwards;
}

.contents.show_ballots {
  animation-name: showBallots;
  animation-duration: $time-duration-moveContentFocus;
  animation-fill-mode: forwards;
}
/*------------------------CSS-CLEAR------------------------*/
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
  background-color: $color-highlight-main !important;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}
