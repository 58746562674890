$color-highlight-main: #DA3232;
$color-highlight-hover: #AD2F2F;
$color-highlight-secondary: #F3B08D;

$color-background: #FFE4B8;
$color-background-light: #FFF1DB;
$color-background-dark: #FDDCB0;
$color-background-modal: rgba(0,0,0,.66);



$color-background-darker: #DA3232;

$color-background-row-zebra: #FACDA5;

$color-text-medium: #FFD083;
$color-text-medium-light: #FFE0AF;
$color-text-light: #FFF1DB;
$color-text-lighter: #FFF1DB;
$color-text-dark: #111111;

$color-small-button: #FDCA9E;
